import { Injectable } from '@angular/core';
import { tap, map, mergeMap, filter, takeUntil, take } from 'rxjs/operators';
import { ChaptersApi } from '../apis/chapters.api.js';
import { ChaptersQuery, ChaptersStore } from '../states/chapters/index.js';
import { combineLatest, of } from 'rxjs';
import { log, pageInfoToAkita } from '@awread/kernel/tools';
@Injectable({ providedIn: 'root' })
export class ChaptersGear {
  constructor(private chaptersApi: ChaptersApi, private chaptersStore: ChaptersStore, private chaptersQuery: ChaptersQuery) { }

  updateChapterTitle(chapterId: string, title: string) {
    this.chaptersStore.update(chapterId, { title });
  }

  fetchLatestChapterPosition(bookId: string) {
    return this.chaptersApi.getLatestPosition(bookId).pipe(
      map(([latestChapter]) => {
        console.log('latestChapter', latestChapter);
        if (latestChapter) {
          return +latestChapter.position + 1;
        } else {
          return 0;
        }
      })
    );
  }


  fetchChangelog(bookId: string) {
    return this.chaptersApi.fetchChangelog(bookId);
  }

  getChapter(bookId: string, chapterId: string) {
    return this.chaptersApi.getChapter(bookId, chapterId).pipe(
      map((chapter: any) => ({
        ...chapter,
        content: chapter?.['content'].content,
        book: chapter?.['book'],
      }))
    );
  }

  getAllChapters(bookId: string, published?: boolean) {
    this.chaptersStore.setLoading(true);
    return this.chaptersApi.getAllChapters(bookId, published).pipe(
      tap((chapters: any) => {
        this.chaptersStore.set(chapters);
      }),
      tap(() => {
        this.chaptersStore.setLoading(false);
      })
    );
  }

  resetChapterPageInfo() {
    this.chaptersStore.resetPageInfo();
  }

  getAllChapterByCursor(offset: number, action: 'set' | 'add' = 'set', published?: boolean | undefined, first = 10) {
    return combineLatest([
      this.chaptersQuery.select((state) => state.currentBookId).pipe(filter((currentBookId) => !!currentBookId)),
      this.chaptersQuery.select((state) => state.ui.sortChapterDesc),
      this.chaptersQuery.select((state) => state.ui.filterFreeChapter),
      this.chaptersQuery.select((state) => state.ui.searchChapter),
      this.chaptersQuery.select((state) => {
        state.pageInfo.hasNextPage, state.pageInfo.endCursor;
      }),
    ]).pipe(
      pageInfoToAkita(
        this.chaptersStore,
        ([bookId, sortChapter, filterFreeChapter, searchChapter]: [string, boolean, string, string]) =>
          this.chaptersApi.getAllChapterByCursor(bookId, offset, sortChapter, filterFreeChapter, searchChapter, first, published),
        action
      ),
      log(),
    );
  }

  getChapterContent(chapterId: string) {
    return this.chaptersApi.getChapterContent(chapterId).pipe(
      map((chapter = {}) => {
        return { ...chapter, content: chapter?.content?.content };
      })
    );
  }

}
