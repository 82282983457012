import { Injectable, inject } from '@angular/core';
import { Order } from '@datorama/akita';
import { ChaptersGear } from '../gears/chapters.gear.js';
import { ChaptersQuery, ChaptersStore } from '../states/chapters/index.js';

@Injectable({ providedIn: 'root' })
export class ChaptersFacade {
  chaptersGear = inject(ChaptersGear);
  chaptersQuery = inject(ChaptersQuery);
  chaptersStore = inject(ChaptersStore);
  chapters$ = this.chaptersQuery.selectAll({ sortByOrder: Order.ASC, sortBy: 'position' });
  chaptersDESC$ = this.chaptersQuery.selectAll({ sortByOrder: Order.DESC, sortBy: 'position' });
  currentBook$ = this.chaptersQuery.select((state) => state.currentBook);

  updateChapterTitle(chapterId: string, title: string) {
    this.chaptersGear.updateChapterTitle(chapterId, title);
  }

  resetChapterStore() {
    this.chaptersStore.reset();
  }

  fetchChangelog(bookId: string) {
    return this.chaptersGear.fetchChangelog(bookId);
  }

  fetchLatestChapterPosition(bookId: string) {
    return this.chaptersGear.fetchLatestChapterPosition(bookId);
  }

  getChapter(bookId: string, chapterId: string) {
    return this.chaptersGear.getChapter(bookId, chapterId);
  }

  getAllChapters(bookId: string, published?: boolean) {
    return this.chaptersGear.getAllChapters(bookId, published);
  }

  resetChapterPageInfo() {
    this.chaptersGear.resetChapterPageInfo();
  }

  getAllChapterByCursor(offset: number, action: 'set' | 'add' = 'set', published?: boolean | undefined) {
    return this.chaptersGear.getAllChapterByCursor(offset, action, published);
  }

  getChapterContent(chapterNid: string) {
    return this.chaptersGear.getChapterContent(chapterNid);
  }

  sortChapterDesc(sortChapterDesc: boolean) {
    return this.chaptersStore.sortChapterDesc(sortChapterDesc);
  }

  filterFreeChapters(filterFreeChapter: any) {
    return this.chaptersStore.filterFreeChapters(filterFreeChapter);
  }

  searchChapter(searchChapter: string) {
    return this.chaptersStore.searchChapter(searchChapter);
  }

  setCurrentBookId(bookId: string) {
    this.chaptersStore.update({ currentBookId: bookId });
  }
  setCurrentBook(book: any) {
    this.chaptersStore.update({ currentBook: book });
  }
}
