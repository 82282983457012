import { Injectable } from '@angular/core';
import { PageInfo } from '@awread/kernel/tools';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Chapter } from '../../models/index.js';

export interface ChaptersState extends EntityState<Chapter>, ActiveState {
  pageInfo: PageInfo;
  currentBookId: string;
  currentBook: any;
  ui: {
    sortChapterDesc: boolean;
    filterFreeChapter: string;
    searchChapter: string;
  };
}

const initialState = {
  pageInfo: {
    endCursor: undefined,
    hasNextPage: true,
    hasPreviousPage: undefined,
    startCursor: undefined,
  },
  currentBookId: undefined,
  currentBook: undefined,
  ui: {
    sortChapterDesc: false,
    filterFreeChapter: '',
    searchChapter: '',
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chapters', resettable: true, idKey: 'chapterId' })
export class ChaptersStore extends EntityStore<ChaptersState> {
  // ui: EntityUIStore<ChapterUIState>;
  constructor() {
    super(initialState);
    // this.createUIStore().setInitialEntityState();
  }

  override akitaPreAddEntity(chapter: Chapter) {
    if (chapter.position) {
      return {
        ...chapter,
        position: +chapter.position,
      };
    }

    return chapter;
  }

  override akitaPreUpdateEntity(prevChapter: Chapter, nextChapter: Chapter) {
    if (nextChapter.position) {
      return {
        ...nextChapter,
        position: +nextChapter.position,
      };
    }
    return nextChapter;
  }

  updatePage(page: { hasNextPage: boolean; totalCount: number; sizePage: number }) {
    this.update(page);
  }

  setCursor(cursor: string) {
    this.update({ cursor });
  }

  resetPageInfo() {
    this.update(initialState);
  }

  filterFreeChapters(filterFreeChapter: any) {
    return this.update((state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          filterFreeChapter,
        },
      };
    });
  }

  sortChapterDesc(sortChapterDesc: any) {
    return this.update((state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sortChapterDesc: sortChapterDesc,
        },
      };
    });
  }

  searchChapter(searchChapter: any) {
    return this.update((state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          searchChapter,
        },
      };
    });
  }

  setCurrentBookId(bookId: string) {
    return this.update({ currentBookId: bookId });
  }
}
