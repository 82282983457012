import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { ChaptersStore, ChaptersState } from './chapters.store.js';
// import { ChaptersStore, ChaptersState, ChaptersUIState } from './chapters.store';

@Injectable({ providedIn: 'root' })
export class ChaptersQuery extends QueryEntity<ChaptersState> {
  chapters$ = this.selectAll().pipe();
  // ui!: EntityUIQuery<ChaptersUIState>;
  constructor(protected override store: ChaptersStore) {
    super(store);
    // this.createUIQuery();
  }

  getHasNextPage() {
    return this.getValue().pageInfo.hasNextPage;
  }

  selectHasNextPage() {
    return this.select((state) => state.pageInfo.hasNextPage);
  }

  selectEndCursor() {
    return this.select((state) => state.pageInfo.endCursor);
  }

  getEndCursor() {
    return this.getValue().pageInfo.endCursor;
  }
}
