import { retry, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class ChaptersApi {
  constructor(private apollo: Apollo) { }

  getLatestPosition(bookId: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query latestBookChapters($bookId: UUID!) {
            chapters(condition: { bookId: $bookId, isExist: 1 }, orderBy: POSITION_DESC, first: 1) {
              nodes {
                bookId
                chapterId
                title
                createdAt
                updatedAt
                published
                position
              }
            }
          }
        `,
        variables: { bookId },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['chapters']?.['nodes']));
  }

  fetchChangelog(bookId: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query fetchChangelog($bookId: UUID!) {
            books(condition: { bookId: $bookId }) {
              nodes {
                title
                description
              }
            }
            chapters(condition: { bookId: $bookId, isExist: 1 }, orderBy: POSITION_DESC, first: 1) {
              nodes {
                bookId
                chapterId
                chapterNid
                title
                createdAt
                updatedAt
                published
                position
              }
            }
          }
        `,
        variables: { bookId },
      })
      .pipe(
        map((res) => ({
          book: res?.['data']?.['books']?.['nodes'][0],
          chapter: res?.['data']?.['chapters']?.['nodes'][0],
        }))
      );
  }

  getAllChapters(bookId: string, published?: boolean | undefined) {
    return this.apollo
      .query<any>({
        query: gql`
          query vChapters($bookId: UUID!, $published: Boolean) {
            vChapters(condition: { bookId: $bookId, isExist: 1, published: $published }, orderBy: POSITION_ASC) {
              nodes {
                bookId
                chapterId
                chapterNid
                title
                createdAt
                updatedAt
                published
                position
                totalView
                totalComment
                words
              }
            }
          }
        `,
        variables: { bookId, published },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['vChapters']?.['nodes']));
  }

  getAllChapterByCursor(
    bookId: string,
    offset: number,
    sortChapterDesc: any,
    filterFreeChapter: any,
    searchChapter: any,
    first = 10,
    published?: boolean | undefined
  ) {
    // console.log('filterFreeChapter?', filterFreeChapter);
    // console.log('sortChapter?', sortChapter);
    return this.apollo
      .query<any>({
        query: gql`
          query allChaptersByCursor(
            $bookId: UUID!
            $offset: Int
            $first: Int
            $published: Boolean
            $includesInsensitive: String
            $orderBy: [VChaptersOrderBy!]
            $have: JSON
          ) {
            vChapters(
              first: $first
              offset: $offset
              orderBy: $orderBy
              condition: { bookId: $bookId, isExist: 1, published: $published, have: $have }
              filter: { title: { includesInsensitive: $includesInsensitive } }
            ) {
              nodes {
                bookId
                chapterId
                chapterNid
                title
                createdAt
                updatedAt
                published
                position
                totalView
                totalComment
                have
                words
              }
              pageInfo {
                endCursor
                hasNextPage
              }
              totalCount
            }
          }
        `,
        variables: {
          bookId,
          offset,
          first,
          published,
          orderBy: sortChapterDesc ? 'POSITION_DESC' : 'POSITION_ASC',
          includesInsensitive: searchChapter !== '' ? searchChapter : '',
          // have: filterFreeChapter === 'freeChapter' ? { free: true } : undefined, // I think you should call all data
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['vChapters']));
  }

  getChapter(bookId: string, chapterId: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query oneChapter($bookId: UUID!, $chapterId: UUID!) {
            chapters(condition: { bookId: $bookId, chapterId: $chapterId }) {
              nodes {
                bookId
                chapterId
                chapterNid
                title
                createdAt
                updatedAt
                published
                position
                book {
                  bookId
                  bookNid
                  title
                  cover
                  published
                  categoryId
                }
                content {
                  content
                }
              }
            }
            booksContests(condition: { bookId: $bookId }) {
              nodes {
                contestNid
              }
            }
          }
        `,
        variables: {
          bookId,
          chapterId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => ({
          ...res?.['data']?.['chapters']?.['nodes']?.[0],
          ...res?.['data']?.['booksContests']?.['nodes']?.[0],
        }))
      );
  }

  getChapterContent(chapterNid: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query getChapterContent($chapterNid: String!) {
            chapters(condition: { chapterNid: $chapterNid }) {
              nodes {
                bookId
                chapterId
                chapterNid
                content {
                  content
                }
              }
            }
          }
        `,
        variables: {
          chapterNid,
        },
      })
      .pipe(map((res) => res?.['data']?.['chapters']?.['nodes']?.[0]));
  }

}
